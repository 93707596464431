<template>
  <sb-page-content title="">
    <sb-dialog-add-Address :show-dialog="showAddressDialog"
                           :vehicle-entity="vehicleEntity"
                           :client-id="clientId"
                           @close="onModalClose"
                           @address-item="addAddressItem" />

    <sb-card :title="currentTitle">
      <v-row align=" start">
        <v-col cols="12"
               class="text-right">
          <v-btn text
                 large
                 color="primary"
                 @click="showAddressDialog=true">
            <v-icon>mdi-plus-circle-outline </v-icon>
            add Address
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               md="12">
          <v-data-table v-model="vehicle.cardDelivery.selected"
                        :headers="ADDRESS_CAPTURE_TABLE_HEADERS"
                        :items="adress"
                        :single-select="singleSelect"
                        empty-message="No address Infomation"
                        empty-sub-message="No address Infomation at this time"
                        item-key="streetAddress1"
                        show-select />
          <template #top>
            <v-switch v-model="singleSelect"
                      label="Single select"
                      class="pa-3" />
          </template>
        </v-col>
      </v-row>
    </sb-card>
  </sb-page-content>
</template>
<script>
import SbDialogAddAddress from '@/components/vehicleCapture/SbDialogAddAddress';
import { BULK_SINGLE_VEHICLE_UPLOAD_HEADERS, ADDRESS_CAPTURE_TABLE_HEADERS } from '@/config/constants';

import { mapActions } from 'vuex';

export default {
  components: {
    SbDialogAddAddress,
    // SbDataTable,
  },
  props: {
    clientId: {
      type: String,
      default: undefined,
    },
    vehicleEntity: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      selected: [],
      singleSelect: true,
      vehicle: this.vehicleEntity,
      adress: [],
      selectedItemIndex: -1,
      selectedAction: '',

      showAddressDialog: false,
      BULK_SINGLE_VEHICLE_UPLOAD_HEADERS,
      ADDRESS_CAPTURE_TABLE_HEADERS,
    };
  },
  created() {
    this.getAddress();
  },
  methods: {
    ...mapActions('vehicleCapture', ['addTransactionItem']),
    ...mapActions('clientAddress', ['getClientAddress', 'addAddressItem']),

    onModalClose() {
      this.showAddressDialog = !this.showAddressDialog;
    },
    async addAddressItem(item) {
      this.adress.push(item);
    },

    async selectedItem(selectedItem) {
      const copySelectedItem = JSON.parse(JSON.stringify(selectedItem));
      this.selectedAction = copySelectedItem.selectedAction;
      this.selectedItemIndex = copySelectedItem.selectedIndex;

      const payload = {
        item: copySelectedItem.selectedItem,
        vehicleCapture: this.vehicleCapture.vehicleCapture,
        selectedItemIndex: this.selectedItemIndex,
      };
      await this.handleSelectedItem(copySelectedItem, payload);
    },

    async handleSelectedItem(selectedItem, payload) {
      let addresses = [];
      if (this.selectedAction === 'Edit') {
        this.showAddressDialog = true;
        // this.cardDelivery = { ...selectedItem.selectedItem };
        this.previousVehicleEntity = JSON.parse(JSON.stringify(selectedItem.selectedItem));

        return;
      }

      if (this.selectedAction === 'Remove') {
        addresses = await this.removeTransationItem(payload);
      }
      this.vehicleCapture.vehicleCapture.addresses = addresses;
    },
    async getAddress() {
      const tempAddress = await this.getClientAddress(this.clientId);
      this.adress = tempAddress.clientCardDeliveryAddress;
    },
  },
};
</script>
