<template>
  <ValidationObserver ref="obs"
                      v-slot="{invalid}">
    <v-row justify="center">
      <v-dialog v-model="dialog"
                persistent
                scrollable
                max-width="700px">
        <v-card>
          <v-toolbar color="#0033a1 !important"
                     dark>
            <v-toolbar-title>Add alternative address</v-toolbar-title>
            <v-spacer />
            <v-btn icon
                   dark
                   left
                   @click="resetForm">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12"
                       sm="6"
                       md="6">
                  <sb-text-field v-model="cardDelivery.contactName"
                                 rules="required"
                                 label="Contact Name" />
                </v-col>
                <v-col cols="12"
                       sm="6"
                       md="6">
                  <sb-text-field v-model="cardDelivery.contactNumber"
                                 rules="required"
                                 :maxlength="10"
                                 label="Contact Number" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                       sm="6"
                       md="6">
                  <sb-text-field v-model="cardDelivery.emailAddress"
                                 rules="required"
                                 label="Email Address" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                       sm="6"
                       md="6">
                  <sb-text-field v-model="cardDelivery.unit"
                                 label="Unit Number" />
                </v-col>
                <v-col cols="12"
                       sm="6"
                       md="6">
                  <sb-text-field v-model="cardDelivery.streetNumber"
                                 rules="selected|required"
                                 label="Street Number" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                       sm="6"
                       md="6">
                  <sb-text-field v-model="cardDelivery.streetName"
                                 rules="required"
                                 label="Street Name" />
                </v-col>
                <v-col cols="12"
                       sm="6"
                       md="6">
                  <sb-text-field v-model="cardDelivery.complexName"
                                 label="Complex Name" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                       sm="6"
                       md="6">
                  <sb-text-field v-model="cardDelivery.suburb"
                                 label="Suburb" />
                </v-col>
                <v-col cols="12"
                       sm="6"
                       md="6">
                  <sb-text-field v-model="cardDelivery.town"
                                 rules="required"
                                 label="City/Town" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                       sm="6"
                       md="6">
                  <sb-text-field v-model="cardDelivery.postalCode"
                                 rules="required"
                                 label="Postal code" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                       sm="6"
                       md="6">
                  <sb-text-area v-model="cardDelivery.specialInstruction"
                                label="Special Instruction for  card delivery" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="secondary"
                   depressed
                   @click="resetForm">
              Cancel
            </v-btn>
            <v-btn color="secondary"
                   depressed
                   :disabled="$wait.any || invalid"
                   @click="addAddres">
              Add
            </v-btn>
          </v-card-actions>
          <v-row>
            <v-col cols="12"
                   md="12" />
          </v-row>
        </v-card>
      </v-dialog>
    </v-row>
  </validationobserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import { BULK_SINGLE_VEHICLE_UPLOAD_HEADERS } from '@/config/constants';
import CardDelivery from '../../models/vehicleCapture/CardDelivery';

export default {
  components: {
    ValidationObserver,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    clientId: {
      type: String,
      default: undefined,
    },
    vehicleEntity: {
      type: Object,
      default: () => undefined,
    },
  },
  data() {
    return {
      cardDelivery: new CardDelivery(),
      BULK_SINGLE_VEHICLE_UPLOAD_HEADERS,
      addressInformationData: [],
      tempAddressInformationData: [],
    };
  },
  computed: {
    dialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {
    resetForm() {
      this.dialog = false;
      this.cardDelivery = new CardDelivery();
    },
    addAddres() {
      this.$emit('address-item', this.cardDelivery);
      this.resetForm();
    },
  },
};
</script>
